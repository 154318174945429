@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #545b6a;
  border-radius: 5px;
  cursor: pointer;
}

::-webkit-scrollbar:horizontal {
  height: 5px;
}

::-webkit-scrollbar-thumb:horizontal {
  background: #545b6a;
  border-radius: 5px;
  cursor: pointer;
}

::-webkit-scrollbar-track:horizontal {
  background: transparent;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}
